export default function (number, weights = [5, 4, 3, 2, 7, 6, 5, 4, 3, 2]) {
	const string = number.toString().replace(/[\s.]+/g, '') // Just in case
	if (string.length !== 11) {
		return false
	} else {
		const checkDigit = parseInt(string.charAt(10), 10)
		const numberWOutCheckDigit = string.substring(0, 10)
		let sum = 0
		for (let i = 0; i < 10; i++) {
			sum += parseInt(numberWOutCheckDigit.charAt(i), 10) * weights[i]
		}
		const reminder = sum % 11
		return checkDigit === (reminder === 0 ? 0 : 11 - reminder)
	}
}
